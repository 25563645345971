<template>
  <v-snackbar
    v-model="props.open"
    shaped
    centered
    background=""
    color="primary"
    outlined
    timeout="-1"
  >
    <v-row>
      <v-col sm="11">
        <strong><slot></slot></strong>
      </v-col>
      <v-col sm="1">
        <v-btn
          icon
          color="primary"
          @click="closeDialog"
        >
          <v-icon>{{ icons.mdiCloseCircle }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mdiCloseCircle } from '@mdi/js'

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const closeDialog = () => {
      emit('close')
    }

    return {
      closeDialog,
      props,
      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>
